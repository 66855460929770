import { Autocomplete, Box, TextField } from "@mui/material";
import { DropDownOption, DropDownProps } from "../models/dropDown.interface";
import { Controller } from "react-hook-form";
import React, { useEffect, useState } from "react";
import Tick from "../assets/icons/tick";
import DropDownIcon from "../assets/icons/dropDownIcon";
const DropDown = ({
  options,
  control,
  label,
  name,
  errors,
  placeholder,
  hint,
  small,
  disabled = false,
  handleOnChange = () => {},
  defaultStartAdornment,
  clearOnBlur,
  dataTestId,
  defaultValue,
  handleOnFocus,
}: DropDownProps) => {
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [openFlag, setOpenFlag] = useState<boolean>(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key.toLowerCase() === "enter") {
      setOpenFlag(!openFlag);
    }
  };

  useEffect(() => {
    setHighlightedIndex(-1);
  }, [options]);

  return (
    <div
      className={`inputSelect ${small && `inputSmall`} ${
        errors && errors?.[name] ? "selectError" : ``
      }`}
      data-testid="inputSelect"
    >
      {label && <label className="label">{label}</label>}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            data-testid={dataTestId}
            openOnFocus={true}
            open={openFlag}
            onOpen={() => setOpenFlag(true)}
            onClose={() => setOpenFlag(false)}
            disabled={disabled}
            defaultValue={defaultValue}
            value={
              options.find((option) => {
                if (typeof value !== "string") {
                  return JSON.stringify(option.value) === JSON.stringify(value);
                } else {
                  return option.value === value;
                }
              }) ?? ""
            }
            clearOnBlur={clearOnBlur}
            freeSolo={true}
            onChange={(
              e: React.SyntheticEvent,
              value: DropDownOption | string | null
            ) => {
              onChange((value as DropDownOption)?.value ?? "");
              handleOnChange(value);
            }}
            options={options}
            renderOption={(props: any, option: DropDownOption, state: any) => (
              <>
                <Box
                  component="li"
                  {...props}
                  className={`multiSelectItem ${
                    state.index === highlightedIndex ? "Mui-focusVisible" : ""
                  }`}
                >
                  <div className="flex optionList alignCenter justifySpaceBetween">
                    <div className="optionList__left">
                      {option.prefix}
                      {option?.bold && <strong>{option?.bold}</strong>}
                      {option.label}
                      {option.pill && option.pill}
                    </div>
                    <div className="optionList__right">
                      <Tick />
                    </div>
                  </div>
                </Box>
              </>
            )}
            renderInput={(params) => {
              const slectedOption = options.find(
                (option) => option.value === value
              );
              const prefix =
                slectedOption || defaultStartAdornment ? (
                  slectedOption?.bold || slectedOption?.prefix ? (
                    <>
                      <span className="selectedOptions">
                        {slectedOption.prefix}
                        {slectedOption?.bold && (
                          <strong>{slectedOption?.bold}</strong>
                        )}
                      </span>
                    </>
                  ) : (
                    defaultStartAdornment
                  )
                ) : (
                  <></>
                );

              return (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder={placeholder}
                  onFocus={handleOnFocus}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: prefix,
                    endAdornment: (
                      <>
                      <div className="selectValueArrow" onClick={() => setOpenFlag(true)}>
                        {small && (
                          <span className="selectValue" >
                            {options &&
                              value &&
                              options.map(
                                (option) =>
                                  option.value === value && option.label
                              )}
                          </span>
                        )}
                        <div className="selectArrow">
                          {params.InputProps.endAdornment}
                          <DropDownIcon />
                        </div>
                        </div>
                      </>
                    ),
                  }}
                  autoComplete="off"
                />
              );
            }}
            onKeyDown={handleKeyDown}
          />
        )}
      />

      {errors && errors?.[name] && (
        <span className="error">{errors?.[name]?.message}</span>
      )}
      {errors && errors?.message && (
        <span className="error">{errors?.message}</span>
      )}
      {hint && <div className="hint">{hint}</div>}
    </div>
  );
};
export default DropDown;
