import { toast } from "react-toastify";
import { takeLatest, put, all } from "redux-saga/effects";
import { getAPI, postAPI, putAPI } from "../../apis/apis";
import * as slice from "./property.reducer";
import * as sagaActions from "./property.sagaAction";
import { handleLoaderForRow } from "../../organisms/leadsTable/reducer";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";

export interface ResponseGenerator {
  [x: string]: any;
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
  follow_up_at?: any;
}

function* fetchPropertyDetails({ payload }: any) {
  const { id } = payload;
  try {
    const response: ResponseGenerator = yield postAPI({
      url: `${process.env.REACT_APP_BASE_URL}/property-details/${id}`,
      headers: {},
    });
    yield put(slice.setPropertyDetails(response));
  } catch (error: any) {
    console.log("error =", error);
    toast.error(error?.response?.data?.message || 'Lead does not exist.');
  } finally {
    yield put(slice.setLoading({ data: false }));
  }
}

function* propertyUpdateDetails({ payload }: any) {
  const { id, followUpAt, status } = payload;
  yield put(handleLoaderForRow({ data: { id, key:"isLoading", value:true }}))
  try {
    yield putAPI({
      url: `${process.env.REACT_APP_BASE_URL}/property-update/${id}`,
      data: { followUpAt: followUpAt, status: status },
      headers: {},
    });
    if(status){
      yield put(handleLoaderForRow({ data: { id, key:"status", value:status }}));
    }
    yield put(handleLoaderForRow({ data: { id, key:"updatedAt", value:new Date()}}))
  } catch (error) {
    console.log("error =", error);
    // yield put(addToast({
    //   id: new Date().getTime(),
    //   message: "Lead not updated!",
    //   type: ToastType.error,
    // }))
    toast.error("Lead not updated!")

  } 
  finally {
    yield put(handleLoaderForRow({ data: { id, key:"isLoading", value:false }}))

  }
}

function* fetchWholesaleCalculator({ payload }: any) {
  const {orgId,id}= payload
  try {
    yield put(slice.setwholesaleCalculatorLoading(true));
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/property-calculator/wholesale/${orgId}/${id}`,
      headers: {},
    });
    yield all([
      put(slice.setwholesaleCalculatorlist(response.data)),
      put(slice.setwholesaleCalculatorLoading(false)),
    ]);
  } catch (error: any) {
    yield all([
      put(slice.setwholesaleCalculatorLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* fetchAmortizationCalculator({ payload }: any) {
  const {orgId,id}= payload
  try {
    yield put(slice.setamortizationCalculatorLoading(true));
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/property-calculator/amortization/${orgId}/${id}`,
      headers: {},
    });
    yield all([
      put(slice.setamortizationCalculatorlist(response.data)),
      put(slice.setamortizationCalculatorLoading(false)),
    ]);
  } catch (error: any) {
    yield all([
      put(slice.setamortizationCalculatorLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* propertyDetailsSaga() {
  yield takeLatest(
    sagaActions.fetchPropertyDetailsAction,
    fetchPropertyDetails
  );
  yield takeLatest(
    sagaActions.propertyUpdateDetailsAction,
    propertyUpdateDetails
  );
  yield takeLatest(
    sagaActions.fetchWholesaleCalculatorAction,
    fetchWholesaleCalculator
  );
  yield takeLatest(
    sagaActions.fetchAmortizationCalculatorAction,
    fetchAmortizationCalculator
  );

  
}

export default propertyDetailsSaga;
