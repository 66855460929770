import { Dialog } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { CreateLeadsProps, CustomError } from "./interface";
import Close from "../../assets/icons/cross";
import Input from "../../atoms/Input";
import { createLeadFormSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { DEFAULT_CREATE_LEAD_DATA } from "./constants";
import Button from "../../atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { fetchAddressList } from "./sagaActions";
import {
  resetCreateLeadState,
  setCreateLeadButtonIsLoading,
  setCreateLeads,
  setNewLeadCreated,
  setSearchList,
  toggleLeadsModal,
} from "./reducer";
import styles from "./CreateLeads.module.scss";
import FileCheck from "../../assets/icons/fileCheck";
// import { UsaStates } from "usa-states";
import MaskedInput from "../../atoms/MaskedInput";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import {
  getOpenCreateLeadModal,
  getLeadButtonLoading,
  getsearchList,
} from "./selector";
import { useDbounce } from "../../hooks/debounce.hook";
import { createLead } from "../../pages/leads/leadsService";

const CreateLeads = ({
  open,
  closeModal,
  addressError,
  setAddressError,
}: CreateLeadsProps) => {
  // const usStatesInstance = new UsaStates();
  // const stateList = usStatesInstance.states;
  // const stateNames = stateList.map((state) => ({
  //   label: state.name,
  //   value: state.name,
  // }));

  const dispatch = useDispatch();
  const submitCliked = useSelector((state: any) => getLeadButtonLoading(state));
  const predictions = useSelector((state: any) => getsearchList(state));
  const [addressTitle, setAddressTitle] = useState("");
  const [enterPressed, setEnterPressed] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    reset,
    clearErrors,
    register,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(createLeadFormSchema),
    defaultValues: DEFAULT_CREATE_LEAD_DATA,
  });
  const openCreateLeadModal = useSelector((state) =>
    getOpenCreateLeadModal(state)
  );

  const debounce = useDbounce(300, (data: any) => {
    dispatch(
      fetchAddressList({
        search: data,
      })
    );
  });

  const handlePredictionClick = (selectedPrediction: any) => {
    if (watch("address")?.length > 2) {
      setValue("address", selectedPrediction.title);
      const selectedPredictionDetails = predictions?.find(
        (prediction: any) => prediction.title === selectedPrediction.title
      );
      setAddressTitle(selectedPrediction.title);
      const { house, street, zip, state, city } = selectedPredictionDetails;
      setValue(
        "addressStreet",
        `${house ? house : ""} ${street ? street : ""}`
      );
      setValue("addressZip", zip ? zip : "");
      setValue("addressState", state ? state : "");
      setValue("addressCity", city ? city : "");
      dispatch(setSearchList([]));
    } else {
      dispatch(setSearchList([]));
    }
  };
  const renderPredictions = () => {
    if (enterPressed) {
      const firstPrediction = predictions && predictions?.[0];
      if (firstPrediction) {
        handlePredictionClick(firstPrediction);
        setEnterPressed(false);
      }
    }

    if (addressTitle === watch("address")) {
      return;
    }
    return (
      <>
        {watch("address")?.length > 2 && (
          <ul className="item-list" data-testid={"addressPredictionsParentId"}>
            {predictions?.map((prediction: any) => (
              <li
                role="link"
                key={prediction.title}
                onClick={() => handlePredictionClick(prediction)}
              >
                {prediction.title}
              </li>
            ))}
          </ul>
        )}
      </>
    );
  };

  useEffect(() => {
    setAddressError(false);
    if (watch("address")?.length > 2) {
      dispatch(setSearchList([]));
      setValue("address", watch("address"));
      debounce(watch("address"));
    } else {
      dispatch(setSearchList([]));
    }
  }, [watch("address")]);

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      if (e.key === "Enter") {
        setEnterPressed(true);
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const onSubmit = async (data: any) => {
    if (addressTitle === "" || addressTitle !== watch("address")) {
      setAddressError(true);
      return;
    }
    try {
      const payload = {
        nameFirst: data?.nameFirst,
        nameLast: data?.nameLast,
        addressStreet: data?.addressStreet,
        addressCity: data?.addressCity,
        addressState: data?.addressState,
        addressZip: data?.addressZip,
        phones: data?.phones,
      };
      dispatch(setCreateLeadButtonIsLoading(true));
      const NewLeadData = await createLead(payload);
      dispatch(setCreateLeads(NewLeadData));
      dispatch(toggleLeadsModal(false));
      dispatch(setNewLeadCreated(true));
      console.log("NewLeadCreated", NewLeadData);
      if (NewLeadData?.data?.message === "success") {
        dispatch(
          addToast({
            id: new Date().getTime(),
            message: "Lead Created Successfully!",
            type: ToastType.success,
          })
        );
      } else {
        dispatch(
          addToast({
            id: new Date().getTime(),
            message: NewLeadData?.data?.message,
            type: ToastType.success,
          })
        );
      }
      window.open(`/properties/${NewLeadData?.data?.property_id}`);
    } catch (error) {
      console.log("error=", error);
      dispatch(
        addToast({
          id: new Date().getTime(),
          message:
            (error as CustomError)?.response?.data?.message ??
            "Lead creation Failed",
          type: ToastType.error,
        })
      );
    } finally {
      dispatch(setCreateLeadButtonIsLoading(false));
    }
  };

  ///Effects
  useEffect(() => {
    setAddressError(false);
    dispatch(setSearchList([]));
    setValue("address", watch("address"));
    debounce(watch("address"));
  }, [watch("address")]);

  useEffect(() => {
    if (!openCreateLeadModal) {
      reset();
      setValue("address", "");
      setAddressTitle("");
    }
  }, [openCreateLeadModal]);

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      if (e.key === "Enter") {
        setEnterPressed(true);
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (
      watch("nameFirst").length > 0 &&
      watch("nameFirst").trim().length === 0
    ) {
      setValue("nameFirst", "");
    }
    if (watch("nameLast").length > 0 && watch("nameLast").trim().length === 0) {
      setValue("nameLast", "");
    }
    if (watch("address").length > 0 && watch("address").trim().length === 0) {
      setValue("address", "");
    }
    if (watch("phones").length > 0 && watch("phones").trim().length === 0) {
      setValue("phones", "");
    }
  }, [watch(["nameFirst", "nameLast", "address", "phones"])]);

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      if (e.key === "Enter") {
        setEnterPressed(true);
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      dispatch(resetCreateLeadState({}));
    };
  }, []);

  return (
    <Dialog
      className="stepsDialog"
      onClose={() => {
        closeModal();
      }}
      open={open}
    >
      <div className="dialogWrapper" data-testid={"createLeadModalId"}>
        <span
          role="link"
          onClick={() => {
            closeModal();
            clearErrors();
          }}
          className="closeDialog"
        >
          <Close />
        </span>
        <div className={`${styles.createLead}`}>
          <div className={`${styles.headerIcon}`}>
            <FileCheck />
          </div>
          <div className={`${styles.createLead__header}`}>
            <h3>Add new Lead</h3>
            <p>Enter the address and other lead information.</p>
          </div>
          <div className={`${styles.mainCol}`}>
            <div className={`${styles.mainCol__half}`}>
              <Input
                type="text"
                label="First Name*"
                register={register}
                name="nameFirst"
                placeholder="Enter first name"
                errors={errors}
              ></Input>
            </div>
            <div className={`${styles.mainCol__half}`}>
              <Input
                type="text"
                label="Last Name*"
                register={register}
                name="nameLast"
                placeholder="Enter last name"
                errors={errors}
              ></Input>
            </div>
          </div>
          <div>
            <div className={`inputGroup`}>
              <span className={`inputGroup__input`}>
                <Input
                  type="text"
                  label="Address*"
                  register={register}
                  name="address"
                  placeholder="Enter an address"
                  errors={errors}
                  invalidAddressError={addressError}
                />
                {renderPredictions()}
              </span>
            </div>
          </div>
          <MaskedInput
            placeholder="Enter the phone number"
            label="Phone Number*"
            name="phones"
            register={register}
            errors={errors}
            maskChar={""}
            mask={"(999) 999-9999"}
          ></MaskedInput>
          <div className={`${styles.createLead__action}`}>
            <Button
              label={"Add Lead"}
              className="primary full animated xl"
              action={handleSubmit(onSubmit)}
              clicked={submitCliked}
              disabled={submitCliked}
            ></Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateLeads;
