import { Helmet } from "react-helmet-async";

const LearnMore = () => {
  return (
    <>
        <Helmet>
            <title>Learn More - OttoLeads</title>
            <script src="//embed.typeform.com/next/embed.js"></script>
        </Helmet>
        <div data-tf-live="01HZQP549K52539D5PRTSVD6WE"></div>
    </>
  );
};

export default LearnMore;
