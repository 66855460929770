import styles from "./Home.module.scss";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import { useDispatch, useSelector } from "react-redux";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import HomeHeroSection from "./homeHeroSection/homeHeroSection";
import MeetOttoSection from "./meetOttoSec/MeetOttoSec";
import SmarterLeadSec from "./smarterLeadSec/SmarterLeadSec";
import BestLeadSec from "./bestLeadSec/BestLeadSec";
import HappyCustomerSec from "./happyCustomerSec/HappyCustomerSec";
import HomeFAQ from "./homeFAQ/HomeFAQ";
import PricingSec from "./pricingSec/PricingSec";
import NewsLetterSignUp from "./newsLetterSignUp/NewsLetterSignUp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopSlider from "./homeSliders/TopSlider";
import BottomSlider from "./homeSliders/BottomSlider";
import { getParamsValue, hasNullValue } from "../../utils/utils";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const meetOttoRef = useRef<HTMLDivElement | null>(null);
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
  const [forgotPassword, setForgotpassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(hasNullValue("email") ? getParamsValue("email") ?? "" : "");
  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const [openVerifyOtp, setOpenVerifyOtp] = useState<boolean>(false);
  const [openEarlyAccessModal, setOpenEarlyAccessModal] = useState<boolean>(false);
  const profileInfo: any = useSelector((state) => getProfileInfo(state));

  // const betaSignup = searchParams.get("beta-signup");
  const createAccount = hasNullValue("create-account") ? getParamsValue("create-account") ?? false : false;

  const upgradeNowFlag = searchParams.get("upgrade-now");

  const openForgotPasswordPopup = () => {
    setOpenLoginModal(false);
    setForgotpassword(true);
  };

  const handleShowToast = (message: string, type: ToastType = ToastType.success) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  // const verifyAccount = async (OTP: string) => {
  //   try {
  //     setOtpVerificationLoading(true);
  //     const data: any = await verifyViaOtp({ email: email, OTP });
  //     handleShowToast(data?.message);
  //     setOpenLoginModal(true);
  //     setOpenVerifyOtp(false);
  //     setOtpVerificationLoading(false);
  //   } catch (err: any) {
  //     console.error(err);
  //     handleShowToast(err.message, ToastType.error);
  //     setOtpVerificationLoading(false);
  //   }
  // };

  useEffect(() => {
    if (profileInfo?.id) {
      switch (profileInfo?.organisation.stepsCompleted) {
        case 0: {
          navigate("/onboarding");
          break;
        }
        case 1: {
          navigate("/onboarding");
          break;
        }
        case 2: {
          navigate("/campaigns");
          break;
        }
      }
    }
  }, [profileInfo]);

  useEffect(() => {
    document.body.classList.add("home-layout");
    return () => {
      document.body.classList.remove("home-layout");
    };
  }, []);

  const scrollToMeetOtto = () => {
    if (meetOttoRef.current) {
      meetOttoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (createAccount === "true") {
      if (localStorage?.getItem("accessToken")) {
        return;
      }
      setOpenRegisterModal(true);
    }
  }, [createAccount, setOpenRegisterModal]);

  useEffect(() => {
    if (upgradeNowFlag && !localStorage.getItem("accessToken")) {
      setOpenLoginModal(true);
      localStorage.setItem("upgrade-now", "true");
    }
  }, [upgradeNowFlag]);

  useEffect(() => {
    document.body.classList.add("home-layout");
    return () => {
      document.body.classList.remove("home-layout");
    };
  }, []);

  useEffect(() => {
    if (profileInfo?.id) {
      switch (profileInfo?.organisation.stepsCompleted) {
        case 0: {
          navigate("/onboarding");
          break;
        }
        case 1: {
          navigate("/onboarding");
          break;
        }
        case 2: {
          navigate("/campaigns");
          break;
        }
      }
    }
  }, [profileInfo]);

  return (
    <>
      <div className={` ${styles.home}`}>
        {/* <Header blackFixed></Header> */}
        <HomeHeroSection
          scrollToMeetOtto={scrollToMeetOtto}
          resetPassword={resetPassword}
          setResetPassword={setResetPassword}
          forgotPassword={forgotPassword}
          setForgotpassword={setForgotpassword}
          openShareModal={openShareModal}
          setOpenShareModal={setOpenShareModal}
          openEarlyAccessModal={openEarlyAccessModal}
          setOpenEarlyAccessModal={setOpenEarlyAccessModal}
          openLoginModal={openLoginModal}
          setOpenLoginModal={setOpenLoginModal}
          email={email}
          setEmail={setEmail}
          setOpenVerifyOtp={setOpenVerifyOtp}
          openForgotPasswordPopup={openForgotPasswordPopup}
          openRegisterModal={openRegisterModal}
          setOpenRegisterModal={setOpenRegisterModal}
        />

        <TopSlider />
        <BottomSlider />
      </div>

      <div ref={meetOttoRef}>
        <MeetOttoSection />
      </div>
      <SmarterLeadSec />
      <BestLeadSec />
      <HappyCustomerSec />
      <PricingSec />
      <HomeFAQ />
      {/* <NewsLetterSignUp /> */}
    </>
  );
};

export default Home;
