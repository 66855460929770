import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  addcreateLeads: null,
  openLeadModal: false,
  leadButtonLoading: false,
  newLeadCreated: false,
  searchList: [],
  // listIsUploading: false,
  // myLists: [],
  // isFileUploading: false, 
};

const createLeadSlice = createSlice({
  name: "addcreateLeads",
  initialState,
  reducers: {
    setCreateLeads: (state, { payload: { data } }) => {
      return {
        ...state,
        addcreateLeads: data,
      };
    },
    toggleLeadsModal: (state, { payload }) => {
      return { ...state, openLeadModal: payload };
    },
    setCreateLeadButtonIsLoading: (state, { payload }) => {
      return { ...state, leadButtonLoading: payload };
    },
    setNewLeadCreated: (state, { payload }) => {
      return { ...state, newLeadCreated: payload };
    },
    setSearchList: (state, { payload }) => {
      return {
        ...state,
        searchList: payload,
      };
    },
    resetCreateLeadState: (state, { payload }) => {
      return initialState;
    },
  },
});

export const {
  setCreateLeads,
  toggleLeadsModal,
  setCreateLeadButtonIsLoading,
  setNewLeadCreated,
  setSearchList,
  resetCreateLeadState,
} = createLeadSlice.actions;

export default createLeadSlice.reducer;
