import { Link, NavLink, useLocation } from "react-router-dom";
import Bell from "../../assets/icons/bell";
import XTwitter from "../../assets/icons/xTwitter";
import styles from "./Footer.module.scss";
import LinkedIn from "../../assets/icons/linkedIn";
import Facebook from "../../assets/icons/facebook";
import Reddit from "../../assets/icons/reddit";
import Instagram from "../../assets/icons/instagram";
import PinTerEst from "../../assets/icons/Pinterest";
import Logo from "../Logo";
import FSquare from "../../assets/icons/fSquare";
import InCircle from "../../assets/icons/InCircle";
import XCircle from "../../assets/icons/xTwitterCircle";
import TwitterCircle from "../../assets/icons/xTwitterCircle";
import InstagramCircle from "../../assets/icons/instaCircle";
const Footer = () => {
  //   let socialShareMessage =
  //     "Check%20out%20%23OttoLeads%2C%20a%20conversational%20AI%20bot%20that%20handles%20ALL%20your%20real%20estate%20cold%20call%20%26%20SMS%20needs%20%F0%9F%A4%AF%20OttoLeads.ai";
  let socialShareMessage =
    "Check out #OttoLeads, a conversational AI bot that handles ALL your real estate outreach using AI! 🤯 OttoLeads.ai";
  const isHomePage =
    useLocation().pathname === "/" && !localStorage.getItem("accessToken");
  const { pathname } = useLocation();
  const excludedPaths = [
    "/verification",
    "/verify-email",
    "/forgot-password",
    "/property-search",
  ];

  const handleClickIntercomLauncher = () => {
    window?.Intercom("show");
  };

  return excludedPaths.every((path) => !pathname.includes(path)) ? (
    <div
      className={`${styles.footer} ${!isHomePage ? styles.footerWhite : ``}`}
    >
      <div
        className={`dflex alignCenter justifySpaceBetween ${styles.footer__inner}`}
      >
        <div className={`${styles.footerLeft}`}>
          {isHomePage && <Logo logoWhite />}
          <p className="font-secondary">
            &copy; OttoLeads, 2024 - All rights reserved.
            {/* {isHomePage && (
            <a href="mailto:sales@ottoleads.ai" target="_blank" rel="noreferrer">
              sales@ottoleads.ai
            </a>
          )} */}
          </p>
        </div>
        <div className={`${styles.footerRight} font-secondary`}>
          <ul className="dflex alignCenter">
            <li>
              <NavLink
                target="_blank"
                to="https://ottoleads.getrewardful.com/signup"
                role="link"
              >
                Affilate
              </NavLink>
            </li>
            <li onClick={handleClickIntercomLauncher}>
              {/* <NavLink target="_blank" to="https://ottoleads.getrewardful.com/signup" role="link"> */}
              Contact Us
              {/* </NavLink> */}
            </li>

            <li>
              <NavLink to="/policies/terms-of-use" role="link">
                Terms
              </NavLink>
            </li>
            <li>
              <NavLink to="/policies/privacy-policy" role="link">
                Privacy
              </NavLink>
            </li>
            <li>
              <NavLink to="/policies/cookies" role="link">
                Cookies
              </NavLink>
            </li>
          </ul>
          {isHomePage && (
            <ul className="dflex alignCenter">
              <li>
                <a
                  href={`https://www.facebook.com/sharer.php?u=https://ottoleads.ai/`}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <FSquare />
                </a>
              </li>
              <li>
                <a
                  href={`https://reddit.com/submit?url=${encodeURIComponent(
                    socialShareMessage
                  )}`}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  className={`${styles.footerRight__reddit}`}
                >
                  <Reddit />
                  {/* <InstagramCircle /> */}
                </a>
              </li>
              <li>
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=https://ottoleads.ai/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <InCircle />
                </a>
              </li>

              <li>
                <a
                  href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    socialShareMessage
                  )}`}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <TwitterCircle />
                </a>
              </li>
            </ul>
          )}
        </div>
        <p className={`${styles.copyrightMobile} font-secondary`}>
          &copy; OttoLeads, 2024 - All rights reserved.
          {/* {isHomePage && (
         <a href="mailto:sales@ottoleads.ai" target="_blank" rel="noreferrer">
           sales@ottoleads.ai
         </a>
       )} */}
        </p>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Footer;
