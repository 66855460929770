import { initialState } from "./reducer";

const getStateData = (state: any) => state["campaignDetail"] || initialState;

export const cancelCampaignResponse = (state: any) => {
  const reducer = getStateData(state);
  return reducer;
};

export const editCampaignResponse = (state: any) => {
  const reducer = getStateData(state);
  return reducer;
};
export const campaignDataResponse = (state: any) => {
  const reducer = getStateData(state);
  return reducer.campaignData;
};

export const isLoading = (state: any) => {
  const reducer = getStateData(state);

  return reducer.isLoading;
};

export const isLoadingEdit = (state: any) => {
  const reducer = getStateData(state);

  return reducer.isLoadingEdit;
};

export const isOpen = (state: any) => {
  const reducer = getStateData(state);

  return reducer.isOpen;
};

export const getTopSectionData = (state: any) => {
  const reducer = getStateData(state);

  return reducer.topSectionData;
};

export const getLeadsData = (state: any) => {
  const reducer = getStateData(state);
  return reducer.leadsData;
};

export const getActivityData = (state: any) => {
  const reducer = getStateData(state);
  return reducer.activityData;
};

export const activityLoading = (state: any) => {
  const reducer = getStateData(state);

  return reducer.activityLoading;
};

export const leadsLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.leadsLoading;
};

export const getTopMatrixData = (state: any) => {
  const reducer = getStateData(state);

  return reducer.topMatrixData;
};

export const getGraphData = (state: any) => {
  const reducer = getStateData(state);

  return reducer.graphData;
};

export const campaignDetailResponse = (state: any) => {
  const reducer = getStateData(state);
  return reducer.campaignDetail;
};

export const getIsAttemptSelected = (state: any) => {
  const reducer = getStateData(state);

  return reducer.isAttemptSelected;
};

export const getPropertyCountByCampaign=(state:any)=>{
 const reducer = getStateData(state);
  return reducer.propertyCountByCampaign
}

export const getAttemptDetails=(state: any)=>{
  const reducer = getStateData(state);
  return reducer.attemptDetails;
}

export const getTotalList=(state: any)=>{
  const reducer = getStateData(state);
  return reducer.total;
}

export const getActivityTotalList=(state: any)=>{
  const reducer = getStateData(state);
  return reducer.activityTotal;
}