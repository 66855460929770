import { Column } from "../../models/table.interface";
import { SortLeadsListKey } from "../../molecules/table/enum";

export const Leads_COLUMN: Column[] = [
  { id: "lead", label: "Lead", type: "titleDescription" },
  {
    id: "datecollected",
    label: "Date collected",
    type: "",
    sortKey: SortLeadsListKey.DATECOLLECTED,
  },
];

export const LeadsDetail_columns = [
  { key: "createdAt", label: "created_at" },
  { key: "name_first", label: "name_first" },
  { key: "name_last", label: "name_last" },
  { key: "street", label: "street" },
  { key: "city", label: "city" },
  { key: "state", label: "state" },
  { key: "status", label: "status"},
  {
    key: "phoneNumber",
    label: "phone_number",
  },
  { key: "summary", label: "phone_attempt_summary" },
];

export const ZONE_TIME = [
  { zone: 1, time_zone: "12 to 3 PM EST" },
  { zone: 2, time_zone: "3 to 6 PM EST" },
  { zone: 3, time_zone: "6 to 9 PM EST" },
];
