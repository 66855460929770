import { Column } from "../../models/table.interface";
import { SortCampaignKey } from "../../molecules/table/enum";

export const campaignTable: readonly Column[] = [
    {
      id: "status",
      label: "Status",
      minWidth: 100,
      type: "status",
      sortKey: SortCampaignKey.STATUS,
    },
    {
      id: "name",
      label: "Campaign name",
      minWidth: 170,
      type: "name",
      sortKey: SortCampaignKey.NAME,
    },
    {
      id: "callCount",
      label: "Calls",
      minWidth: 110,
      type: "number",
      sortKey: SortCampaignKey.CALL_SMS,
    },
    {
      id: "inQueueCalls",
      label: "In Queue",
      minWidth: 110,
      type: "number",
      sortKey: SortCampaignKey.IN_QUEUE,
    },
    {
      id: "leadCount",
      label: "Leads",
      minWidth: 110,
      type: "number",
      sortKey: SortCampaignKey.LEADS,
    },
    {
      id: "voiceEmail",
      label: "Voicemail",
      minWidth: 110,
      type: "number",
      sortKey: SortCampaignKey.VOICEMAIL,
    },
    {
      id: "hangupCalls",
      label: "Hung Up",
      minWidth: 110,
      type: "number",
      sortKey: SortCampaignKey.HANGUP,
    },
    {
      id: "deadCalls",
      label: "Dead",
      minWidth: 110,
      type: "number",
      sortKey: SortCampaignKey.DEAD,
    },
    {
      id: "wrongNumberCalls",
      label: "Wrong #",
      minWidth: 110,
      type: "number",
      sortKey: SortCampaignKey.WRONG_NUMBER,
    },
    {
      id: "failedCalls",
      label: "Disconnected",
      minWidth: 110,
      type: "number",
      sortKey: "",
    },
    {
      id: "dncCalls",
      label: "DNC",
      minWidth: 110,
      type: "number",
      sortKey: SortCampaignKey.DNC,
    },
    {
      id: "startDate",
      label: "Start Date",
      minWidth: 170,
      type: "string",
      sortKey: SortCampaignKey.START_DATE,
    },
  ];