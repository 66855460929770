import { Column } from "../../models/table.interface";
import { formatCurrency } from "../../utils/utils";

export const mortgageCurrentColumns: Record<string, any>[] = [
  { id: "loanPosition", label: "Loan Position ", minWidth: 170, type: "" },
  { id: "recordingDate", label: "Recording Date", minWidth: 70, type: "" },
  { id: "loanAmount", label: "Loan Amount", minWidth: 70, type: "" },
  { id: "estRate", label: "Est. Rate", minWidth: 70, type: "" },
  { id: "documentNumber", label: "Document Number", minWidth: 70, type: "" },
  { id: "deedType", label: "Deed Type", minWidth: 70, type: "" },
  { id: "lenderName", label: "Lender Name", minWidth: 70, type: "" },
  { id: "lenderType", label: "Lender Type", minWidth: 70, type: "" },
  { id: "granteeName", label: "Grantee Name(s)", minWidth: 70, type: "" },
  { id: "loanType", label: "Loan Type", minWidth: 70, type: "" },
];

export const mortgageHistoryColumns: Record<string, any>[] = [
  { id: "loanPosition", label: "Loan Position ", minWidth: 170, type: "" },
  { id: "recordingDate", label: "Recording Date", minWidth: 70, type: "" },
  { id: "loanAmount", label: "Loan Amount", minWidth: 70, type: "" },
  { id: "estRate", label: "Est. Rate", minWidth: 70, type: "" },
  { id: "documentNumber", label: "Document Number", minWidth: 70, type: "" },
  { id: "deedType", label: "Deed Type", minWidth: 70, type: "" },
  { id: "lenderName", label: "Lender Name", minWidth: 70, type: "" },
  { id: "lenderType", label: "Lender Type", minWidth: 70, type: "" },
  { id: "granteeName", label: "Grantee Name(s)", minWidth: 70, type: "" },
  { id: "loanType", label: "Loan Type", minWidth: 70, type: "" },
];

export const saleColumns: Record<string, any>[] = [
  { id: "dateOfSale", label: "Date of Sale", minWidth: 170, type: "" },
  { id: "amount", label: "Amount", minWidth: 70, type: "" },
  { id: "purchaseMethod", label: "Purchase Method", minWidth: 70, type: "" },
  { id: "documentType", label: "Document Type", minWidth: 70, type: "" },
  { id: "transactionType", label: "Transaction Type", minWidth: 70, type: "" },
  { id: "sellerName", label: "Seller Name(s)", minWidth: 70, type: "" },
  { id: "buyerName", label: "Buyer Name(s)", minWidth: 70, type: "" },
];

export const amortizationScheduleColumns: readonly Column[] = [
  { id: "period", label: "Period", minWidth: 70, type: "number" },
  {
    id: "interest",
    label: "Interest",
    minWidth: 70,
    type: "number",
    format: formatCurrency,
  },
  {
    id: "principal",
    label: "Principal",
    minWidth: 70,
    type: "number",
    format: formatCurrency,
  },
  {
    id: "endingBalance",
    label: "Ending Balance",
    minWidth: 70,
    type: "number",
    format: formatCurrency,
  },
];

export const currentLinkedPropertiesColumns: readonly Column[] = [
  { id: "address", label: "Address", minWidth: 70, type: "string" },
];


export const ProfitMarginOptions = [
  { label: "70%", value: 0.7 },
  { label: "75%", value: 0.75},
  { label: "80%", value: 0.8},
];