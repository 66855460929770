import { postAPI } from "../../apis/apis";

export const postWholesaleCalculator = async (payload:any) => {
    return postAPI({
      url:
      `${process.env.REACT_APP_BASE_URL}/property-calculator/wholesale`,
      headers: {},
      data: payload,
    });
  };

  export const postAmortizationCalculator = async (payload:any) => {
    return postAPI({
      url:
      `${process.env.REACT_APP_BASE_URL}/property-calculator/amortization`,
      headers: {},
      data: payload,
    });
  };

 