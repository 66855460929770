import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isLoading: true,
  data: {},
  wholesaleCalculatorlist:{},
  wholesaleCalculatorLoading:false,
  amortizationCalculatorlist:{},
  amortizationCalculatorLoading:false
};

const propertyDetailsSlice = createSlice({
  name: "propertyDetails",
  initialState,
  reducers: {
    setPropertyDetails: (state, { payload: { data } }) => {
      return {
        ...state,
        data: data,
      };
    },
    setLoading: (state, { payload: { data } }) => {
      return {
        ...state,
        isLoading: data,
      };
    },
    resetPropertyDetailsState:(state, { payload }) => {
      return initialState;
    },
    setwholesaleCalculatorlist: (state, { payload }) => {
      return {
        ...state,
        wholesaleCalculatorlist: payload,
      };
    },
    setwholesaleCalculatorLoading: (state, { payload}) => {
      return {
        ...state,
        wholesaleCalculatorLoading: payload,
      };
    },
    setamortizationCalculatorlist: (state, { payload }) => {
      return {
        ...state,
        amortizationCalculatorlist: payload,
      };
    },
    setamortizationCalculatorLoading: (state, { payload }) => {
      return {
        ...state,
        amortizationCalculatorLoading: payload,
      };
    }
  },
});

export const { setPropertyDetails, setLoading, resetPropertyDetailsState,setwholesaleCalculatorlist ,setwholesaleCalculatorLoading,setamortizationCalculatorlist,setamortizationCalculatorLoading} =
  propertyDetailsSlice.actions;

export default propertyDetailsSlice.reducer;
